import React from "react"
import { Location } from "@reach/router"
import queryString from "query-string"

import Search from "../templates/search"
const SearchRoute = () => {
  return (
    <Location>
      {({ location, navigate }) => (
        <Search
          location={location}
          navigate={navigate}
          search={location.search ? queryString.parse(location.search) : {}}
        />
      )}
    </Location>
  )
}

export default SearchRoute
