import React from "react"
import { Link } from "gatsby"
import Moment from "react-moment"
const ArticleCard = data => {
  const dataArticle = data.article
  return (
    <div className="article-post">
      <Link
        className="article-post__title"
        to={`/info/${dataArticle.Slug !== null ? dataArticle.Slug : "#"}`}
      >
        {dataArticle.Title}
      </Link>

      <h5>
        <Moment format="YYYY年 MM月 DD日">{dataArticle.DatePublication}</Moment>
        {dataArticle.article_categories === undefined ? (
          ""
        ) : (
          <>
            {dataArticle.article_categories.map((categorie, i) => (
              <Link
                key={i}
                className="article-post__tag"
                to={`/info-cat/${categorie.Slug}`}
              >
                <span>{categorie.Name}</span>
              </Link>
            ))}
          </>
        )}
      </h5>

      <div className="article-post__description">
        <p>{dataArticle.Description}</p>
        {!dataArticle.Image ? (
          ""
        ) : (
          <Link to={`/info/${dataArticle.Slug}`} aria-label={dataArticle.Slug}>
            <img
              src={
                dataArticle.Image.publicURL
                  ? dataArticle.Image.publicURL
                  : dataArticle.Image.url
              }
              alt=""
              width="200"
              height="200"
            />
          </Link>
        )}
      </div>
    </div>
  )
}

export default ArticleCard
